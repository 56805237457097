import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from "gatsby-plugin-image"
import { financeSection, financeTitle } from '../components/layout.module.css'

const imageWidth = 300
const imageHeight = 300

const IndexPage = () => {
  return (
    <Layout pageTitle="Projects">
      <div>
        <h2>Artillery Kai</h2>
        <a href='https://www.artillerykai.co.uk/' target='_blank'>
        <StaticImage src="../images/artillerykai.png"
          alt="Artillery Kai (Muay Thai and BJJ)"
          placeholder="blurred"
          layout="fixed"
          width={imageWidth}
          height={imageHeight} />
        </a>
        <p>
        Friendly local MMA gym in Woolwich. Training mostly in Muay Thai and BJJ, but also Wrestling and Judo.
        </p>
      </div>
      <div>
        <h2>Brainy Warriors</h2>
        <a href='https://www.brainywarriors.com/' target='_blank'>
        <StaticImage src="../images/brainywarriors.png"
          alt="Brainy Warriors (Educational Game)"
          placeholder="blurred"
          layout="fixed"
          width={imageWidth}
          height={imageHeight} />
        </a>
        <p>
        An educational game for children where you power through your adventures by doing spellings and maths.
        </p>
      </div>
      <div>
        <h2>Finance, Data Science / Machine Learning</h2>
        <StaticImage src="../images/finance.png"
          alt="Finance, Data Science / Machine Learning"
          placeholder="blurred"
          layout="fixed"
          width={imageWidth}
          height={imageHeight} />
        <div className={financeSection}>
          <div className={financeTitle}>KYDB for finance. No-SQL DB Abstraction and more.</div>
          <a href='https://github.com/tayglobal/kydb'>Github</a>
          <a href='https://kydb.readthedocs.io/'>Documentation</a>
        </div>
        <div className={financeSection}>
          <div className={financeTitle}>Demo on leveraging python in finance</div>
          <a href='https://github.com/tayglobal/kinyu-demo'>Kinyu Demo</a>
        </div>
        <div className={financeSection}>
          <div className={financeTitle}>Emagine Epython</div>
          <a href='https://github.com/tayglobal/epython-showcase'>Github</a>
          <a href='https://doc.epythoncloud.io/html/index.html'>Webpage</a>
        </div>
      </div>
      <div>
        <h2>JiuJitsu Sekai</h2>
        <a href='https://www.jiujitsu-sekai.com/' target='_blank'>
        <StaticImage src="../images/jiujitsu-sekai.png"
          alt="Jiujitsu Sekai"
          placeholder="blurred"
          layout="fixed"
          width={imageWidth}
          height={imageHeight} />
        </a>
        <p>
          A 3D App that aims to help Brazilian Jiujitsu practitioner improve their techniques.
        </p>
      </div>
    </Layout>
  )
}

export default IndexPage
